/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, Fragment } from "react";
import {
  Grid,
  Row,
  Col,
  Button,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import "./Survey.css";

class Survey extends Component {

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              
            <Card
                title="Downloads"
                content={
                  <Fragment>
                    <Row>
                      <Col md={12}>
                        <p>Open design in the FOSS4G community presents opportunities for intuitive software design.</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <h5><strong>Design Dimensions Survey Questions</strong></h5>
                        <p>This overview document is a static download of all questions published as part of the FOSS Design Dimensions survey.</p>
                        <Button href={process.env.PUBLIC_URL + '/downloads/Design_Dimensions_Survey_Public.pdf'}>Download</Button>
                      </Col>
                      <Col md={4}>
                        <h5><strong>Survey Questions</strong></h5>
                        <p>This is a formatted version of the FOSS Design Dimension survey questions and can be viewed using a free Qualtrics account <a href="https://www.qualtrics.com/">here</a>. <br /><br />An administrator account is required to publish and host a live survey.</p>
                        <Button href={process.env.PUBLIC_URL + '/downloads/Open_Source_Travel_Mapping_Tools_2019.qsf'}>Download</Button>
                      </Col>
                      <Col md={4}>
                        <h5><strong>Survey Results</strong></h5>
                        <p>This download contains raw data collected from users during the survey which ran for six weeks in August 2019. Where applicable, all personal data has been removed and responses are anonymous.  Columns 1 and 2 are headers. <br /><br />Note: For the purposes of this research effort, we removed and only analyzed responses with less than 65 percent completion.</p>
                        <Button href={process.env.PUBLIC_URL + '/downloads/Design_Dimensions_Survey_Results_2019.xlsx'}>Download</Button>
                      </Col>
                    </Row>
                  </Fragment>
                }
              />

              <Card
                title="Prototype"
                content={
                  <Fragment>
                    <div
                      className="video"
                      style={{
                        position: "relative",
                        paddingBottom: "56.25%" /* 16:9 */,
                        paddingTop: 25,
                        height: 0
                      }}
                    >
                      <iframe title="youtube"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%"
                        }}
                        src={`https://www.youtube.com/embed/0R-uq4I4XLQ`}
                        frameBorder="0"
                      />
                    </div>
                  </Fragment>
                }
              />

              <Card
                title="Survey Results"
                content={
                  <Fragment>
                    <div
                      className="google-slides-container"
                    >
                      <iframe title="slides"
                        src="https://docs.google.com/presentation/d/e/2PACX-1vSlHChOZU5sarobxtAlBsZY1pBVRrZE3q2C9aMfA8ZdNO08qLLN46ZwvbuAR5ut2J9c7H64ZYKj6xEv/embed?start=false&loop=true&delayms=30000"
                        frameborder="0" width="960" height="569" allowfullscreen="true"
                        mozallowfullscreen="true" webkitallowfullscreen="true">
                      </iframe>
                    </div>

                  </Fragment>
                }
              />

            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

}

export default Survey;
