/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, Fragment } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Image,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import avatar from "assets/img/faces/brookelynn.png";
import pennState from "assets/img/penn-logo.jpg";

class Welcome extends Component {

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>


              <Row>
                <Col md={12}>
                  <Card
                    title="Presentation Details"
                    content={
                      <Fragment>
                        <Button href="https://2019.foss4g.org/schedule/presentations/">Link to FOSS4G Presentations</Button>

                        <h3><strong>Destination Unknown: Design Dimensions of Open Source Travel Mapping Tools</strong>
                        <br />
                        <small>Parallel Sessions Round 3</small>
                        </h3>
                        <br />
                        <Row>
                          <Col md={4}>
                            <h6>Date</h6>
                            <p>Thursday, 29 August 2019</p>
                          </Col>
                          <Col md={4}>
                            <h6>Time</h6>
                            <p>12:00 pm - 12:20 pm</p>
                          </Col>
                          <Col md={4}>
                            <h6>Location</h6>
                            <p>InterContinental Bucharest</p>
                          </Col>
                        </Row>

                        <h3>Abstract</h3>
                        <p>
                        How can interactive maps help people plan great travel experiences? Destination travel is a popular way to experience the world for millions of people. Until now, little research has been conducted to establish what this type of user might actually need in a map-centric application. Most current approaches do not emphasize the role of space and place in travel preparation to help users understand the spatial dimensions of their travel plans. In addition, most of the available trip planning systems are proprietary in nature. Our work seeks to develop an application that goes beyond providing support for simple itinerary creation. We present results from a user survey to assess key spatially-supported travel task needs and design preferences. This data helps characterize the core functional needs for a FOSS web-GIS application and accompanying user interface designed specifically for destination travelers. 
                        </p>

                        <h4>Why FOSS4G Design?</h4>
                        <p>Intuitive user design choices are critical for developing web-based spatial planning tools for destination travel. Our work offers an approach that focuses on user preferences to create a FOSS environment that facilitates map-oriented discovery and efficient itinerary planning.The availability and accessibility of open source geospatial solutions presents an opportunity to design a map-centric application that accommodates users looking to organize, compile, present, and share their recreational travel plans. Current proprietary and free applications that attempt to serve this cohort rarely go beyond simple itinerary organization. A FOSS approach presents opportunities to avoid the drawbacks associated with proprietary solutions that often seek to influence travel decision-making by monetizing user choices and data capture with advertisements or requiring payment for certain features. While structured data entry applications, like field collection tools, may be helpful in travel planning activities, research that addresses user preferences and catalogues feedback for displaying destination travel data is needed in order to support future open source design and development efforts. In our survey, users were asked to rate/rank utility of specific functions including feature creation, data presentation in calendar or map view, routing (air, road, train, walking) from point to point, sharing and collaborating on itineraries, and the ability to upload photos. Additional functionality like export capabilities and an API that allows for integration into desktop GIS applications, like QGIS, is also evaluated.</p>


                        <Row>
                          <Col md={4}>
                            <h5><i>Contribution Topic</i></h5>
                            <p>Use cases & applications</p>
                          </Col>
                          <Col md={4}>
                            <h5><i>General Theme</i></h5>
                            <p>Community & Participatory FOSS4G</p>
                          </Col>
                          <Col md={4}>
                            <h5><i>Level of complexity for this talk</i></h5>
                            <p>1 - No technical background required</p>
                          </Col>
                        </Row>
                      </Fragment>
                    }
                  />
                </Col>
              </Row>




              <Row>
                <Col md={12}>
                  <Card
                    title="Sign Up For Updates"
                    content={
                      <form action="https://formspree.io/info@destinationunknown.io" method="POST">
                        <FormInputs
                          ncols={["col-md-6", "col-md-6"]}
                          properties={[
                            {
                              label: "First name",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "First name",
                              defaultValue: "",
                              name: "first_name",
                            },
                            {
                              label: "Last name",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Last name",
                              defaultValue: "",
                              name: "last_name",
                            }
                          ]}
                        />
                        <FormInputs
                          ncols={["col-md-6", "col-md-6"]}
                          properties={[
                            {
                              label: "Email",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Email",
                              defaultValue: "",
                              name: "emailaddress",
                            },
                            {
                              label: "Country",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Country",
                              defaultValue: "",
                              name: "country",
                            }
                          ]}
                        />
                        <Row>
                          <Col md={12}>
                            <FormGroup controlId="formControlsTextarea">
                              <ControlLabel>Comments / Feedback</ControlLabel>
                              <FormControl
                                rows="5"
                                componentClass="textarea"
                                bsClass="form-control"
                                placeholder="Add your comments and feedback here"
                                defaultValue=""
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button bsStyle="info" pullRight fill type="submit">
                          Submit
                        </Button>
                        <div className="clearfix" />
                      </form>
                    }
                  />
                </Col>
              </Row>





            </Col>
            <Col md={4}>
              <UserCard
                bgImage="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
                avatar={avatar}
                name="Brookelynn Constant"
                userName="M.S. in Geospatial Information Systems"
                description={
                  <span>
                    Brookelynn is working towards completing her Master’s in Geospatial Information Systems (GIS) at the Pennsylvania State University. She currently works for the federal government as a senior geospatial analyst and project manager. She also serves as a teaching assistant for introductory spatial analysis classes at Penn State’s Department of Geography.
                    <br /><br />
                    Brookelynn holds a BA in Political Science from Christopher Newport University and a Graduate Certificate in Geospatial Intelligence from the Pennsylvania State University.
                    <br /><br />
                    She currently lives in Saint Louis where she is a board member for a community-focused start up organization that supports STEM and GIS learning initiatives for students.
                    <br /><br />
                    <Image src={pennState} />
                  </span>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

}

export default Welcome;
