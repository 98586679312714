/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Welcome from "views/Welcome.jsx";
import Survey from "views/Survey.jsx";
import Map from "views/Map.jsx";

const dashboardRoutes = [
  {
    path: "/welcome",
    name: "Welcome",
    icon: "pe-7s-map-marker",
    component: Welcome,
    layout: "/app"
  },
  {
    path: "/survey",
    name: "Survey",
    icon: "pe-7s-note2",
    component: Survey,
    layout: "/app"
  },
  {
    path: "/map",
    name: "Map",
    icon: "pe-7s-map",
    component: Map,
    layout: "/app"
  },
];

export default dashboardRoutes;
